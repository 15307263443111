<template>
  <div>
      <Echart :options="options" id="RightBottomChart" height="400px" width="100%">
      </Echart>
  </div>
</template>

<script>
import Echart from '@/common/echart/index.vue'

export default {
  data() {
    return {
      options: {},
    };
  },
  components: {
    Echart,
  },
  props: {
    cdata: {
      type: Object,
      default: () => ({})
    },
  },
  watch: {
    cdata: {
      handler(newData) {
        this.options = {
          useUTC: true,
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              animation: false
            }
          },
          grid: {
            top: '15%',
            left: '2%',
            right: '4%',
            bottom: '3%',
            containLabel: true
          },
          xAxis: {
            type: 'time',
            axisLabel: {
              textStyle: {// 设置字体颜色
                fontSize: 16, // 设置字体大小
                fontWeight: 'bold'
              },
            },
            splitLine: {
              show: false
            },

          },
          yAxis: {
            type: 'value',
            splitLine: {show: true},
            axisLabel: {
              textStyle: {// 设置字体颜色
                fontSize: 16 // 设置字体大小
              }
            },
            name: '件/天',
            nameTextStyle: {
              fontSize: 16,
              fontWeight: 'bold'
            }
          },
          series: [
            {
              name: '产能',
              data: newData.data,
              type: 'line',
              showSymbol: false,
              tooltip: {
                valueFormatter: function (value) {
                  if (Number.isInteger(value)) {
                    return value + '件/天';
                  } else {
                    // 保留一位小数
                    return value.toFixed(1) + '件/天';
                  }
                }
              },
            }
          ]
        }
      },
      immediate: true,
      deep: true
    },
  },
}
</script>
<template>
  <div>
    <Echart
        :options="options"
        id="LeftChart"
        height="850px"
        width="100%"
    ></Echart>
  </div>
</template>

<script>
import Echart from "@/common/echart/index.vue"

export default {
  data() {
    return {
      options: {},
    };
  },
  components: {
    Echart,
  },
  props: {
    cdata: {
      type: Object,
      default: () => ({})
    },
  },
  watch: {
    cdata: {
      handler(newData) {
        console.log(newData)
        this.options = {
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow'
            }
          },
          legend: {
            top: '20',
            left: 'center',
            textStyle: {
              fontSize: 20,
              fontWeight: 'bold'
            },
          },
          grid: {
            top: '10%',
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
          },
          xAxis: [
            {
              type: 'category',
              data: newData.category,
              axisLabel: {
                textStyle: {
                  fontSize: 20, // 设置字体大小
                  fontWeight: 'bold'
                },
                interval: 0,
              },
            }
          ],
          yAxis: [
            {
              type: 'value',
              splitLine: {show: true},
              axisLabel: {
                textStyle: {
                  fontSize: 16 // 设置字体大小
                },
                formatter: function (value) {
                  return value + 'min';
                }
              },

            }
          ],
          series: [
            {
              name: '传送带运行时间',
              type: 'bar',
              emphasis: {
                focus: 'series'
              },
              stack: 'Ad',
              barWidth: '30%',   // 设置柱子宽度为类目宽度的 20%
              barGap: '10%',     // 设置间隔为柱子宽度的 10%
              data: newData.data[0],
              tooltip: {
                valueFormatter: function (value) {
                  if (Number.isInteger(value)) {
                    return value + 'min';
                  } else {
                    // 保留一位小数
                    return value.toFixed(2) + 'min';
                  }
                }
              },
            },
            {
              name: '传送带停机时间',
              type: 'bar',
              stack: 'Ad',
              emphasis: {
                focus: 'series'
              },
              barWidth: '30%',   // 设置柱子宽度为类目宽度的 20%
              barGap: '10%',     // 设置间隔为柱子宽度的 10%
              data: newData.data[1],
              tooltip: {
                valueFormatter: function (value) {
                  if (Number.isInteger(value)) {
                    return value + 'min';
                  } else {
                    // 保留一位小数
                    return value.toFixed(2) + 'min';
                  }
                }
              },
            },
          ]
        }
      },
      immediate: true,
      deep: true
    },
  },
}
</script>

<template>
  <div id="oee" ref="appRef">
    <div class="bg">
      <div class="oee-body">
        <MyHeader :title="title"></MyHeader>

        <div class="body-box">
          <!-- 左侧数据 -->
          <div class="left-box">
            <div>
              <dv-border-box-12>
                <left :beltTime="beltTime"/>
              </dv-border-box-12>
            </div>
          </div>

          <!-- 右侧数据 -->
          <div class="right-box">
            <div class="right-top-box">
              <div>
                <dv-border-box-12>
                  <LeftTop :beltProductivity="this.beltProductivity" />
                </dv-border-box-12>
              </div>
            </div>
            <div class="right-bottom-box">
              <div>
                <dv-border-box-12>
                  <LeftBottom :beltSpeed="this.beltSpeed"/>
                </dv-border-box-12>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import MyHeader from "../../components/header/myHeader.vue";
import drawMixin from "@/utils/drawMixin";
import rightTopRight from "@/views/oee/rightTopRight.vue";
import rightBottom from "@/views/oee/rightBottom.vue";
import beltlive from "@/api/beltlive";
import belt from "@/api/belt";
import LeftTop from "./leftTop.vue";
import LeftBottom from "./leftBottom.vue";
import Left from "./left.vue";

export default defineComponent({
  name: "oee",
  mixins: [drawMixin],
  data() {
    return {
      title: "传送带实况",
      oee_rank: [],
      loss_reason: [],
      oee_trend: [],
      beltProductivity: [],
      beltSpeed: [],
      beltTime: [],
    };
  },
  components: {
    rightBottom,
    rightTopRight,
    MyHeader,
    LeftBottom,
    LeftTop,
    Left,
  },
  mounted() {
    //setInterval 轮询策略待实现
    setInterval(() => {
      this.fetchData();
    }, 3000);
  },
  created() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      await beltlive.getBeltProductivity().then(
        (response) => {
          this.beltProductivity = response.data.data
        }
      );
      // await beltlive.getBeltSpeed(1).then(
      //   (response) => {
      //     // console.log(response.data.data)
      //     this.beltSpeed = response.data.data
      //   }
      // )
      await belt.getBeltInfo(1).then(
        (response) => {
          this.beltSpeed = response.data.data
        }
      )
      await beltlive.getBeltTime(1).then(
        (response) => {
          this.beltTime = response.data.data
        }
      )
    },
  },
});
</script>

<style scoped lang="scss">
@import "../../assets/scss/oee.scss";
</style>

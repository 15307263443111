<template>
  <div>
    <Echart
        :options="options"
        id="RightBottomChart"
        height="400px"
        width="100%"
    ></Echart>
  </div>
</template>

<script>
import Echart from '@/common/echart/index.vue'

export default {
  data() {
    return {
      options: {},
      speed:[[],[],[],[],[]],
      date: [],
      flag: 1,
    };
  },
  components: {
    Echart,
  },
  props: {
    beltSpeed: {
      type: Array,
    },
  },
  methods: {
    getTime(h,m,s) {
      for (let i = 1; i <= 100; i++) {
        this.date.push(`${h}:${m}:${s}`)
        if(s >= 3) {
          s = s - 3; 
        }
        else {
          s = s + 60 - 3;
          if (m >= 1) {
            m = m - 1;
          }
          else {
            m = m + 60 - 1;
            h = h - 1;
          }
        }
      }
      this.date.reverse()
    },
  },
  watch: {
    beltSpeed: {
      handler(newData) {
        // console.log("new")
        // console.log(newData)

        let now = new Date()
        const h = now.getHours();
        const m = now.getMinutes();
        const s = now.getSeconds();
        const time = `${h}:${m}:${s}`

        if (newData.length == 5 && this.flag) {
          this.speed[0].length = 100
          this.speed[1].length = 100
          this.speed[2].length = 100
          this.speed[3].length = 100
          this.speed[4].length = 100
          this.speed[0].fill(newData[0].speed)
          this.speed[1].fill(newData[1].speed)
          this.speed[2].fill(newData[2].speed)
          this.speed[3].fill(newData[3].speed)
          this.speed[4].fill(newData[4].speed)
          this.flag = 0;
        }

        if (this.date.length == 0) {
          this.getTime(h,m,s);
        }
        else {
          this.date.push(time) //进一个
          this.date.shift() //出一个
          this.speed[0].push(newData[0].speed)
          this.speed[0].shift()
          this.speed[1].push(newData[1].speed)
          this.speed[1].shift()
          this.speed[2].push(newData[2].speed)
          this.speed[2].shift()
          this.speed[3].push(newData[3].speed)
          this.speed[3].shift()
          this.speed[4].push(newData[4].speed)
          this.speed[4].shift()
        }

        // this.date.push(time)
        // console.log(this.date)

        // this.speed[0].push(newData[0].speed)
        // this.speed[1].push(newData[1].speed)
        // this.speed[2].push(newData[2].speed)
        // this.speed[3].push(newData[3].speed)
        // this.speed[4].push(newData[4].speed)
        // console.log(this.speed)

        this.options = {
          tooltip: {
            trigger: 'axis'
          },
          legend: {
            top: '2%',
            data: ['belt1','belt2','belt3','belt4','belt5'],
            textStyle: {// 设置字体颜色
              fontSize: 14, // 设置字体大小
            }
          },
          grid: {
            top: '10%',
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
          },
          xAxis: {
            // type: 'time',
            splitLine: {
              show: false,
            },
            axisLabel: {
              textStyle: {// 设置字体颜色
                fontSize: 16, // 设置字体大小
                fontWeight: 'bold'
              }
            },
            data: this.date
          },
          yAxis: {
            type: 'value',
            splitLine: {
              show: true,
              lineStyle: {
                type: 'dashed'
              }
            },
            axisLabel: {
              textStyle: {// 设置字体颜色
                fontSize: 16 // 设置字体大小
              },
              formatter: '{value}'
            },

          },
          series: [
            {
              name: 'belt1',
              type: 'line',
              // stack: 'Total',
              symbol: 'none',
              data: this.speed[0],
              tooltip: {
                valueFormatter: function (value) {
                  return value;
                }
              },
            },
            {
              name: 'belt2',
              type: 'line',
              // stack: 'Total',
              symbol: 'none',
              data: this.speed[1],
              tooltip: {
                valueFormatter: function (value) {
                  return value;
                }
              },
            },
            {
              name: 'belt3',
              type: 'line',
              symbol: 'none',
              // stack: 'Total',
              data: this.speed[2],
              tooltip: {
                valueFormatter: function (value) {
                  return value;
                }
              },
            },
            {
              name: 'belt4',
              type: 'line',
              // stack: 'Total',
              symbol: 'none',
              data: this.speed[3],
              tooltip: {
                valueFormatter: function (value) {
                  return value;
                }
              },
            },
            {
              name: 'belt5',
              type: 'line',
              // stack: 'Total',
              symbol: 'none',
              data: this.speed[4],
              tooltip: {
                valueFormatter: function (value) {
                  return value;
                }
              },
            }
          ]
        }
      },
      immediate: true
    },
  },
}
</script>

<template>
  <div>
    <Chart :cdata="cdata"/>
  </div>
</template>

<script>
import Chart from './chart.vue'

export default {
  data() {
    return {
      cdata: {
        data:[]
      }
    };
  },
  components: {
    Chart,
  },
  props: {
    beltProductivity: {
      type: Array,
      required: true,
    },
  },
  watch: {
    beltProductivity: {
      handler: function (newVal) {
        // console.log("----------------")
        // console.log(newVal)
        this.cdata.data = []
        newVal.forEach((item, index) => {
          this.cdata.data[index] = [item.created_at, item.count]
        })
      },
      deep: true
    }
  },
  mounted() {
  },
  methods: {}
};
</script>